.word {
  white-space: nowrap;
}
/* don't apply the gradient style on firefox */
@supports (not (-moz-appearance: none)) {
  .word {
    background-image: linear-gradient(90deg, #1da9e6 0%, #6966fe 82%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
